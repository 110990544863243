module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-11989088-8","head":true,"anonymize":true,"cookieDomain":"havardbergersen.com","respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Håvard Bergersen","start_url":"/","background_color":"#101820","theme_color":"#F2AA4C","display":"standalone","icon":"src/assets/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3435a619c3c231a0e9aeb829871a4841"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
